import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

type FormData = {
  cpf: string
  ticket: string
  isTicketValid: boolean
  fullName: string
  email: string
  emailConfirmation: string
  phone: string
  hasNoAccount?: boolean
  qrCode?: string
  cnpj: string
  companyName: string
  message: string
  bu: 'benefits' | 'expense' | 'people'
  reason: string
  // role?: string
  attachments?: File[]
  attachmentUrls?: string[]
  zipCode: string
  address: string
  addressNumber: string
  complement?: string
  neighborhood: string
  city: string
  state: string
  referencePoint?: string
  recipientName: string
  recipientEmail: string
  recipientPhone: string
}

type AppStateContextType = {
  formState: FormData
  setFormState: Dispatch<SetStateAction<FormData>>
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  cancelModalOpen: boolean
  setCancelModalOpen: Dispatch<SetStateAction<boolean>>
  resetState: () => void
}

type AppStateProviderProps = {
  children: ReactNode
}

export const AppStateContext = createContext({} as AppStateContextType)

const initialFormState: FormData = {
  cpf: '',
  ticket: '',
  isTicketValid: false,
  fullName: '',
  email: '',
  emailConfirmation: '',
  phone: '',
  hasNoAccount: false,
  qrCode: undefined,
  cnpj: '',
  companyName: '',
  message: '',
  bu: undefined,
  reason: null,
  // role: '',
  attachments: [],
  attachmentUrls: [],
  zipCode: '',
  address: '',
  addressNumber: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',
  referencePoint: '',
  recipientName: '',
  recipientEmail: '',
  recipientPhone: '',
}

export function AppStateProvider({ children }: AppStateProviderProps) {
  const [currentStep, setCurrentStep] = useState(1)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [formState, setFormState] = useState<FormData>(initialFormState)

  function resetState() {
    setFormState(initialFormState)
  }

  return (
    <AppStateContext.Provider
      value={{
        formState,
        setFormState,
        currentStep,
        setCurrentStep,
        cancelModalOpen,
        setCancelModalOpen,
        resetState,
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}

export function useAppState() {
  const context = useContext(AppStateContext)

  if (!context) {
    throw new Error('useAppState must be used within an AppStateProvider')
  }

  return context
}
